import * as React from "react";

function OpeningHours({ styles, content }) {
  return (
    <div style={styles.SectionContainerStyle}>
      <h2 style={styles.TitleTextStyle}>{content.title}</h2>
      <div style={styles.SubSectionContainerStyle}>
        {content.texts.map((text) => (
          <div style={styles.columnStyle}>
            <p style={styles.textStyle}>{text.day}</p>
            <p style={styles.textStyle}>{text.hours}</p>
          </div>
        ))}
      </div>
      <div style={styles.SubSectionContainerStyle}></div>
    </div>
  );
}

export default OpeningHours;
