export const HomeTopSectionContent = {
  maintext: "Willkommen im Mundus",
  mainTitleWelcome: "./Willkommen_white.png",
  address: "Kamp 12 • 33098 Paderborn",
  contactsTitle: "Reservierungen gerne unter:",
  phone: "05251 5314949",
  mail: "info@mundus-pb.de",
  insta: "https://www.instagram.com/mundus_pb/",
  instaText: "Instagram: mundus_pb",
};

export const menuSectionContent = {
  menus: "./Speisekarte.pdf",
};

export const intro = {
  title: "Über Uns",
  text: [
    "Mit dem Mundus holen wir ein Stück der großen, weiten Welt nach Paderborn. Unsere Gerichte sind inspiriert von unseren Reisen in nahe und ferne Länder. Sie erzählen die Geschichten von Orten, die wir besucht und Menschen, die wir getroffen haben. Sie sind lieb gewonnene Erinnerungen an Momente, an die wir gern zurückdenken.",
    "Heute laden wir Sie ein, mit uns auf eine kulinarische Reise zu gehen. Wir wünschen Ihnen schöne Stunden im Mundus.",
    "Schön, dass Sie da sind!",
  ],
  names: "Olli und Roland",
  picture: "url(../about_picture.jpg)",
};

export const OpeningHoursContent = {
  title: "Öffnungszeiten",
  texts: [
    { day: "Di. - Do.", hours: "09:00 - 15:00 17:00 - 23:00" },
    { day: "Fr.", hours: "09:00 - 15:00 17:00 - 00:00" },
    { day: "Sa.", hours: "09:00 - 01:00" },
    { day: "So.", hours: "10:00 - 15:00 17:00 - 23:00" },
  ],
};

export const PictureBoxesContent = [
  { pic: "url(../Beilagengemüse.jpg)", key: "1" },
  { pic: "url(../Bruschetta.jpg)", key: "2" },
  { pic: "url(../Curry.jpg)", key: "3" },
  { pic: "url(../Drinks.jpg)", key: "4" },
  { pic: "url(../Fbuffet.png)", key: "8" },
  { pic: "url(../Frühstück.jpg)", key: "5" },
  { pic: "url(../Kartoffel.jpg)", key: "7" },
  { pic: "url(../Raum2.jpg)", key: "6" },
  { pic: "url(../Zapfender_Olli.jpg)", key: "9" },
];

export const ImpressumSectionContent = {
  title1: "Mundus Paderborn",
  times: [
    { day: "Di. - Do.", hours: "09:00 - 15:00 17:00 - 23:00" },
    { day: "Fr.", hours: "09:00 - 15:00 17:00 - 00:00" },
    { day: "Sa.", hours: "09:00 - 01:00" },
    { day: "So.", hours: "10:00 - 15:00 17:00 - 23:00" },
  ],
  name: "Mundus",
  address1: "Kamp 12",
  address2: "33098 Paderborn",
  phone: "Telefon: 05251 5314949",
  mail: "E-Mail: info@mundus-pb.de",
  insta: "https://www.instagram.com/mundus_pb/",
  instaText: "Instagram: mundus_pb",
};
