import * as React from "react";
import ImpressumSection from "./elements/ImpressumSection.js";
import { useLayoutEffect } from "react";
import * as Content from "./Content";
import * as Styles from "./Styles";

const pageStyles = {
  fontFamily: "Gill Sans",
};
const ImpressumContainerStyle = {
  paddingTop: "3%",
  width: window.innerWidth > 800 ? "50vw" : "75vw",
  margin: window.innerWidth > 800 ? "5% auto" : "20% auto",
  fontSize: "1.2rem",
  fontWeight: "200",
};
const TitleStyle = {
  fontWeight: "400",
  fontFamily: "Gill Sans",
};
const pStyle = {
  margin: "2px 0",
};

const ImpressumPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main style={pageStyles}>
      <div style={ImpressumContainerStyle}>
        <h2 style={TitleStyle}>IMPRESSUM</h2>

        <p>Angaben gemäß § 5 TMG</p>

        <p style={pStyle}>Agile Gastro Gmbh</p>
        <p style={pStyle}>Kamp 12</p>
        <p style={{ marginTop: "2px" }}>33098 Paderborn</p>

        <p style={pStyle}>Vertreten durch:</p>
        <p style={pStyle}>Oliver Vogt</p>

        <p>Handelsregister: B 16328</p>

        <p>Registergericht: Amtsgericht Paderborn</p>

        <p>Steuernummer: 339/5800/1951</p>

        <h3 style={TitleStyle}>Kontakt</h3>
        <p>Telefon: 05251 5314949</p>
        <p>E-Mail: info@mundus-pb.de</p>
      </div>

      <ImpressumSection
        styles={Styles.ImpressumSectionStyles}
        content={Content.ImpressumSectionContent}
      ></ImpressumSection>
    </main>
  );
};

export default ImpressumPage;
