import * as React from "react";

function MenuSection({ styles }) {
  return (
    <div style={styles.SectionContainerStyle}>
      <div style={styles.menuBoxStyle} key="1">
        <h2 style={styles.TitleStyle}>Frühstück</h2>
        <p style={styles.TextStyle}>Di - Sa: 9:00 - 11:30, So: 10-15</p>
        <br></br>
        <a
          style={styles.linkStyle}
          target={"_blank"}
          rel="noreferrer"
          href="https://dl.dropboxusercontent.com/scl/fi/kduvbl6n9txqbbcysn6yk/Fr-hst-ckskarte.pdf?rlkey=ojblji2ldr7lvkr0yfptj3irv&dl=0/Frühstückskarte.pdf"
          download
        >
          Frühstückskarte
        </a>
      </div>
      <div style={styles.menuBoxStyle} key="2">
        <h2 style={styles.TitleStyle}>Mittagstisch</h2>
        <p style={styles.TextStyle}>Di - Fr: 12:00 - 14:30</p>
        <br></br>
        <a
          style={styles.linkStyle}
          target={"_blank"}
          rel="noreferrer"
          href="https://dl.dropboxusercontent.com/scl/fi/331mzth6ngywj6f50nfzu/Mittagskarte.pdf?rlkey=p4cpl18jvhbs0ka2y3vmzz6hc&dl=0/Mittagskarte.pdf"
          download
        >
          Mittagskarte
        </a>
      </div>
      <div style={styles.menuBoxStyle} key="3">
        <h2 style={styles.TitleStyle}>Speisekarte</h2>
        <p style={styles.TextStyle}>Sa: 12:00 - 14:30, Di - So: ab 17:00</p>
        <br></br>
        <a
          style={styles.linkStyle}
          target={"_blank"}
          rel="noreferrer"
          href="https://dl.dropboxusercontent.com/scl/fi/jocmcvnkx6k5n34dtsc5l/Speisekarte.pdf?rlkey=lpeoa8nhno9opl9ty9nhg9r6g&dl=0/Speisekarte.pdf"
          download
        >
          Speisekarte
        </a>
      </div>
    </div>
  );
}

export default MenuSection;
