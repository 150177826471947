import * as React from "react";

function IntroSection({ styles, content }) {
  return (
    <div style={styles.SectionContainerStyle}>
      <div style={styles.SubContainerStyle}>
        <div style={styles.FotoCenterDivStyle}>
          <div
            style={{
              ...styles.PictureStyle,
              ...{ backgroundImage: content.picture },
            }}
          ></div>
        </div>
      </div>
      <div style={styles.SubContainerStyle}>
        <h2 style={styles.TitleTextStyle}>{content.title}</h2>
        {content.text.map((item) => (
          <p style={styles.textStyle}>{item}</p>
        ))}
        <p style={styles.namesStyle}>{content.names}</p>
      </div>
    </div>
  );
}

export default IntroSection;
