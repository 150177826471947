import * as React from "react";
import OpeningHours from "./OpeningHours.js";
import * as Content from "../Content";
import * as Styles from "../Styles";

function HomeTopSection({ styles, content }) {
  return (
    <div style={styles.SectionContainerStyle}>
      <img
        style={styles.WelcomeTitleStyles}
        src={content.mainTitleWelcome}
        alt={content.maintext}
      ></img>

      <div style={styles.InnerContainerStyles}>
        <div style={styles.InnerDivStyle}>
          <OpeningHours
            styles={Styles.OpeningHoursStyles}
            content={Content.OpeningHoursContent}
          ></OpeningHours>
        </div>
        <div style={styles.InnerDivStyle}>
          <p style={styles.textStyle}>{content.address}</p>
          <br></br>
          <p style={styles.contactsTitleStyle}>{content.contactsTitle}</p>
          <p style={styles.textStyle}>{content.phone}</p>
          <p style={styles.textStyle}>{content.mail}</p>
          <a
            style={styles.linkStyle}
            href={content.insta}
            target={"_blank"}
            rel="noreferrer"
          >
            <div style={styles.instaLinkStyle}></div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomeTopSection;
