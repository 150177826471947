export const brandGreen = "#016932";
export const goldHoverColor = "#e6c300";

export const HomeTopSectionStyles = {
  SectionContainerStyle: {
    margin: "0",
    width: "100%",
    color: "white",
    textAlign: "center",
    fontFamily: "Gill Sans",
    backgroundSize: window.innerWidth > 900 ? "cover" : "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(../mainBanner.png)",
    opacity: window.innerWidth > 900 ? "0.9" : "1",
    minHeight: "80vh",
  },
  WelcomeTitleStyles: {
    padding: window.innerWidth > 900 ? "10% 0 5% 0" : "20% 0 5% 0",
    width: window.innerWidth > 900 ? "80%" : "90%",
  },
  InnerContainerStyles: {
    display: "flex",
    flexDirection: window.innerWidth > 900 ? "row" : "column",
    fontSize: window.innerWidth > 900 ? "1.5rem" : "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "400",
  },
  InnerDivStyle: {
    padding: window.innerWidth > 900 ? "5% 10%" : "15% 5%",
    width: window.innerWidth > 900 ? "25%" : "75%",
    minWidth: "fit-content",
  },
  textStyle: {
    margin: 0,
  },
  contactsTitleStyle: {
    margin: "0 0 2% 0",
  },
  linkStyle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  instaLinkStyle: {
    height: "50px",
    width: "50px",
    backgroundSize: "cover",
    cursor: "pointer",
    backgroundImage: "url(../insta_white.png)",
  },
};

export const OpeningHoursStyles = {
  SectionContainerStyle: {
    margin: 0,
    textAlign: "center",
  },
  TitleTextStyle: {
    marginBottom: window.innerWidth > 900 ? 30 : 20,
    fontFamily: "Gill Sans",
    fontSize: window.innerWidth > 900 ? "2rem" : "1.1rem",
    fontWeight: "400",
  },
  columnStyle: {
    display: "grid",
    gridTemplateColumns: "35% auto",
  },
  textStyle: {
    fontFamily: "Gill Sans",
    textAlign: "left",
    margin: 0,
    fontWeight: "400",
    fontSize: window.innerWidth > 900 ? "1.25rem" : "0.9rem",
  },
};

export const IntroStyles = {
  SectionContainerStyle: {
    margin: window.innerWidth > 900 ? "auto" : "20% auto 0 auto",
    fontFamily: "Gill Sans",
    display: "flex",
    justifyContent: "center",
    width: "80%",
    height: "fit-content",
    flexDirection: window.innerWidth > 900 ? "row" : "column",
    padding: "5%",
  },
  SubContainerStyle: {
    width: window.innerWidth > 900 ? "70%" : "100%",
    padding: window.innerWidth > 900 ? "5%" : "0",
    alignItems: "center",
  },
  FotoCenterDivStyle: {
    display: window.innerWidth > 900 ? "flex" : "block",
    height: "100%",
  },
  PictureStyle: {
    aspectRatio: "1 / 1",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  TitleTextStyle: {
    fontFamily: "Gill Sans",
    fontSize: "2rem",
    fontWeight: "300",
    textAlign: "center",
    paddingTop: window.innerWidth > 900 ? "0" : "15%",
  },
  textStyle: {
    fontFamily: "Gill Sans",
    fontSize: "1.25rem",
    fontWeight: "300",
    textAlign: "justify",
  },
  scrollLink: {
    fontWeight: "bolder",
  },
  namesStyle: {
    fontFamily: "cursive",
    textAlign: "left",
    fontSize: "1.25rem",
  },
};

export const MenuSectionStyles = {
  SectionContainerStyle: {
    margin: "auto",
    marginBottom: window.innerWidth > 900 ? "10%" : "20%",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: window.innerWidth > 900 ? "75%" : "100%",
    flexDirection: window.innerWidth > 900 ? "row" : "column",
  },
  menuBoxStyle: {
    width: window.innerWidth > 900 ? "30%" : "90%",
    margin: "20px 0",
    textAlign: "center",
  },
  TitleStyle: {
    fontFamily: "Gill Sans",
    fontSize: "2rem",
    fontWeight: "300",
    color: brandGreen,
    paddingTop: window.innerWidth > 900 ? "0" : "15%",
  },
  TextStyle: {
    fontFamily: "Gill Sans",
    fontSize: "1.2rem",
    fontWeight: "300",
    marginTop: 0,
  },
  linkStyle: {
    cursor: "pointer",
    color: "inherit",
    fontSize: "1.25rem",
  },
};

export const PictureBoxesStyles = {
  SectionContainerStyle: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: window.innerWidth > 900 ? "75%" : "100%",
    flexDirection: window.innerWidth > 900 ? "row" : "column",
  },
  BoxStyle: {
    width: window.innerWidth > 900 ? "30%" : "90%",
    margin: "20px 0",
    aspectRatio: "1 / 1",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};

export const CookiesBannerStyles = {
  cookiesPopupContainer: {
    width: "100%",
    height: "fit-content",
    marginBottom: 0,
    position: "fixed",
    background: brandGreen,
    left: "0%",
    right: "0%",
    bottom: "0%",
    margin: "0",
    zIndex: 10,
  },
  TitleTextStyle: {
    fontSize: "1.25rem",
    fontWeight: "300",
    fontFamily: "Gill Sans",
    textAlign: "center",
    color: "white",
  },
  cookiesButton: {
    margin: "5px 0",
    borderColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: brandGreen,
    padding: "8px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    color: "white",
  },
  buttonPaddingStyle: {
    paddingLeft: "5%",
  },
};

export const ImpressumSectionStyles = {
  SectionContainerStyle: {
    width: "100%",
    height: "fit-content",
    margin: 0,
    padding: window.innerWidth > 900 ? "0" : "15% 0",
    marginTop: "5%",
    display: "flex",
    flexDirection: window.innerWidth > 900 ? "row" : "column",
    justifyContent: "center",
    fontFamily: "Gill Sans",
    fontSize: window.innerWidth > 900 ? "1.2rem" : "1rem",
    color: "white",
    fontWeight: "300",
    background: brandGreen,
  },
  TextBlockStyle: {
    display: "inline",
    margin: "auto",
    paddingBottom: "8%",
    paddingTop: "3%",
    paddingLeft: window.innerWidth > 900 ? "5%" : 0,
    textAlign: window.innerWidth > 900 ? "left" : "center",
    width: window.innerWidth > 900 ? "25%" : "100%",
  },
  InnerDivStyle: {
    width: "fit-content",
    margin: "auto",
  },
  columnStyle: {
    display: "grid",
    gridTemplateColumns: "30% auto",
  },
  pStyle: {
    margin: "5px 0",
  },
  LinkStyle: {
    marginTop: window.innerWidth > 900 ? "5%" : 0,
    textDecoration: "none",
    cursor: "pointer",
    color: "inherit",
  },
};
