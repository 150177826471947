import React from "react";

function CookiesBanner({ styles, handleCookiesAccept }) {
  return (
    <div style={styles.cookiesPopupContainer}>
      <p style={styles.TitleTextStyle}>
        Diese Webseite verwendet technisch notwendige Cookies.
        <span style={styles.buttonPaddingStyle}>
          <button style={styles.cookiesButton} onClick={handleCookiesAccept}>
            Einverstanden
          </button>
        </span>
      </p>
    </div>
  );
}

export default CookiesBanner;
