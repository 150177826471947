import React from "react";
import { useState } from "react";
import IntroSection from "./elements/IntroSection.js";
import MenuSection from "./elements/MenuSection.js";
import PictureBoxes from "./elements/PictureBoxes.js";
import ImpressumSection from "./elements/ImpressumSection.js";
import CookiesBanner from "./elements/CookiesBanner.js";
import HomeTopSection from "./elements/HomeTopSection.js";
import * as Content from "./Content";
import * as Styles from "./Styles";

const AppStyle = {
  textAlign: "center",
  color: "#232129",
  fontFamily: "Gill Sans, sans-serif, serif, -apple-system, Roboto,",
  lineHeight: "1.5",
};

function App() {
  const [cookiesAccepted, setcookiesAccepted] = useState(false);

  let cookiesPopupSeen = localStorage.getItem("cookies");

  const handleCookiesAccept = () => {
    localStorage.setItem("cookies", 1);
    setcookiesAccepted(true);
  };

  return (
    <div style={AppStyle}>
      {!cookiesAccepted && cookiesPopupSeen !== "1" ? (
        <CookiesBanner
          styles={Styles.CookiesBannerStyles}
          handleCookiesAccept={handleCookiesAccept}
        ></CookiesBanner>
      ) : null}

      <HomeTopSection
        styles={Styles.HomeTopSectionStyles}
        content={Content.HomeTopSectionContent}
      ></HomeTopSection>

      <IntroSection
        styles={Styles.IntroStyles}
        content={Content.intro}
      ></IntroSection>

      <MenuSection
        styles={Styles.MenuSectionStyles}
      ></MenuSection>

      <PictureBoxes
        styles={Styles.PictureBoxesStyles}
        content={Content.PictureBoxesContent}
      ></PictureBoxes>

      <ImpressumSection
        styles={Styles.ImpressumSectionStyles}
        content={Content.ImpressumSectionContent}
      ></ImpressumSection>
    </div>
  );
}

export default App;
