import * as React from "react";

function PictureBoxes({ styles, content }) {
  return (
    <div style={styles.SectionContainerStyle}>
      {content.map((item) => (
        <div
          style={{ ...styles.BoxStyle, ...{ backgroundImage: item.pic } }}
          key={item.key}
        ></div>
      ))}
    </div>
  );
}

export default PictureBoxes;
