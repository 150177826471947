import * as React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { goldHoverColor } from "../Styles";

function ImpressumSection({ styles, content }) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div style={styles.SectionContainerStyle}>
      <div style={styles.TextBlockStyle}>
        <p>Öffnungszeiten</p>
        {content.times.map((text) => (
          <div style={styles.columnStyle}>
            <p style={styles.pStyle}>{text.day}</p>
            <p style={styles.pStyle}>{text.hours}</p>
          </div>
        ))}
      </div>
      <div style={styles.TextBlockStyle}>
        <div style={styles.InnerDivStyle}>
          <p>{content.name}</p>
          <p style={styles.pStyle}>{content.address1}</p>
          <p style={styles.pStyle}>{content.address2}</p>
          <br></br>
          <p style={styles.pStyle}>{content.phone}</p>
          <p style={styles.pStyle}>{content.mail}</p>
          <a
            style={styles.LinkStyle}
            href={content.insta}
            target={"_blank"}
            rel="noreferrer"
          >
            {content.instaText}
          </a>
        </div>
      </div>
      <div style={styles.TextBlockStyle}>
        <div style={styles.InnerDivStyle}>
          <Link
            style={
              isHover
                ? { ...styles.LinkStyle, ...{ color: goldHoverColor } }
                : styles.LinkStyle
            }
            to={"/impressum"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <p>Impressum</p>
          </Link>
          {/* <p>Datenschutzerklärung</p> */}
        </div>
      </div>
    </div>
  );
}

export default ImpressumSection;
